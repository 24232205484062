/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppStore } from "@/piniaStore/AppStore";
import { useAuthStore } from "@/piniaStore/AuthStore";
import axios from "axios";
import { axiosErrorInterceptor } from "./axiosUtils";

const AxiosNodes = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_NODES_API,
  // timeout: 1000,
  headers: { accept: "application/json" },
});

AxiosNodes.interceptors.request.use((config) => {
  const authStore = useAuthStore();
  const appStore = useAppStore();
  const secretKey = sessionStorage.getItem("nodeSecret");

  (config as Record<string, any>).headers.Authorization = `Bearer ${authStore.token}`;
  (config as Record<string, any>).headers["X-NODE-ACTIVATION-KEY"] = secretKey;
  (config as Record<string, any>).headers["accept-language"] = appStore.language;
  return config;
});

AxiosNodes.interceptors.response.use((res) => res, axiosErrorInterceptor);

export default AxiosNodes;
