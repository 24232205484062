import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import toast from "vue-toastification";
import getI18n from "./libs/i18n/index";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import Vue3Toasity from "vue3-toastify";
import * as Sentry from "@sentry/vue";

import "vue-toastification/dist/index.css";
import "./registerServiceWorker";
import "vue3-toastify/dist/index.css";
import "@/assets/scss/style.scss";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://f28d05b5c71dcd6dd9c91f490a878e5f@o4505974668460032.ingest.sentry.io/4505974669574144",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "yupa.io","app.yupa.io", "staging.api.yupa.io"
    ],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(pinia).use(getI18n()).use(toast).use(router).use(Vue3Toasity).mount("#app");
