import { io, Socket } from "socket.io-client";
import { PiniaStores } from ".";
import { defineStore } from "pinia";
import { useAuthStore } from "./AuthStore";
import { ClientToServerEvents, ServerToClientEvents } from "yupa-types";

export type YupaSocketType = Socket<ServerToClientEvents, ClientToServerEvents>

type State = {
  privateSocket: YupaSocketType  | null;
};

export const useSocketStore = defineStore(PiniaStores.SOCKET_IO_STORE, {
  state: (): State => ({
    privateSocket: null,
  }),
  actions: {
    initSocket() {
      if (this.privateSocket) {
        this.privateSocket.disconnect();
        this.privateSocket.close();
      }
      const authStore = useAuthStore();
      if (authStore.token) {
        const link = `${process.env.VUE_APP_NODES_API}private`;
        this.privateSocket = io(link, {
          auth: {
            token: `Bearer ${authStore.token}`,
          },
        });
      }
    },
  },
});
