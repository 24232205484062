import router from "@/router";
import nodeAxios from "@/libs/axios/NodeAxios";
import axios, { AxiosError, AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
import { PiniaStores } from ".";
import { AuthService } from "@/services/httpServies/AuthService";
import { useSocketStore } from "./SocketIoStore";
import { baseNodesResponseType } from "@/types/nodesApiResponseTypes";
import { postRefreshTokenResponseType } from "yupa-types";
import AxiosNodes from "@/libs/axios/NodeAxios";

type User = {
  id: string;
  email: string;
  role_id: number;
  name: string;
};

interface State {
  logedIn: boolean;
  refreshToken: string | null;
  token: string | null;
  tfaToken?: string;
  user: User | null;
}

export const useAuthStore = defineStore(PiniaStores.AUTH_STORE, {
  // convert to a function
  persist: true,
  state: (): State => ({
    logedIn: false,
    token: null,
    user: null,
    refreshToken: null,
  }),
  actions: {
    updateTokens({ token, refreshToken }: { token: string; refreshToken: string }) {
      this.token = token;
      this.refreshToken = refreshToken;
    },
    setLoggedIn({
      user,
      token,
      refreshToken,
    }: {
      user: User;
      token: string;
      refreshToken: string;
    }) {
      this.logedIn = true;
      this.user = user;
      this.token = token;
      this.refreshToken = refreshToken;
    },
    async login({ email, password }: { email: string; password: string }) {
      const authService = new AuthService();

      const data = await authService.login({ email, password });

      // TODO handle TFA
      // if (data.) {
      //   this.tfaToken = data.token;
      //   router.push("/tfa");
      //   return;
      // }

      this.setLoggedIn({ user: data.user, token: data.token, refreshToken: data.refreshToken });
      const socketService = useSocketStore();
      socketService.initSocket();
      if (data.user.role_id === 1) {
        router.push("/dashboard");
      } else if (data.user.role_id === 3) {
        router.push({ name: "admin.users" });
      }
    },
    async getNewTokens() {
      if (!this.refreshToken) {
        return;
      }
      const { data }: AxiosResponse<baseNodesResponseType<postRefreshTokenResponseType>> =
        await AxiosNodes.post("api/v1/auth/refresh-token", {
          refreshToken: this.refreshToken,
        });

      const { refreshToken, token } = data.data;
      this.updateTokens({ refreshToken, token });
    },
    async getMe() {
      try {
        const authService = new AuthService();
        const data = await authService.getMe();

        const user: User = {
          id: data.user.id,
          email: data.user.email,
          role_id: data.user.role_id,
          name: data.user.name,
        };

        this.setLoggedIn({ user, token: this.token || "", refreshToken: this.refreshToken || "" });

        if(data.user.is_email_verified == false) {
          router.push({ name: "VerifyEmail" });
          return
        }

        if(data.user.is_banned){
          router.push({ name: "Banned" });
          return
        }

      } catch (err) {
        if (!axios.isAxiosError(err)) {
          return router.push("login");
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const axiosError = err as AxiosError<any>;
        if (axiosError.response) {
          const response = axiosError.response;
          if (response.status == 401) {
            return router.push("login");
          }
          if (response.status == 403) {
            const user = response?.data.data.user;
            this.setLoggedIn({
              user,
              token: this.token ?? "",
              refreshToken: this.refreshToken || "",
            });
            return router.push({ name: "VerifyEmail" });
          }
        }

        this.logedIn = false;
        this.user = null;
        this.token = null;
        router.push("login");
      }
    },
    // TODO fix this
    async loginTFA({ code }: { code: string }) {
      const toast = useToast();
      try {
        const { data } = await nodeAxios.post("/api/v1/sign-in/tfa", {
          token: this.tfaToken,
          code,
        });

        this.setLoggedIn({ user: data.data.user, token: data.data.token, refreshToken: "" });
        router.push("/wallet");
      } catch (err) {
        toast.error("Wrong code");
      }
    },
    async register({
      email,
      password,
      name,
      notBotToken,
      referralCode,
    }: {
      email: string;
      password: string;
      name: string;
      notBotToken: string;
      referralCode?: string
    }) {
      const authService = new AuthService();

      const registerResponse = await authService.register({ email, password, name, notBotToken, referralCode });

      const user: User = {
        email: registerResponse.user.email,
        id: registerResponse.user.id,
        name: registerResponse.user.name,
        role_id: +registerResponse.user.role_id,
      };
      const token: string = registerResponse.token;

      this.setLoggedIn({ user, token, refreshToken: registerResponse.refreshToken });

      router.push("/wallet");
    },
    logOut() {
      this.logedIn = false;
      this.user = null;
      this.token = null;
      setTimeout(() => {
        window.location.href = "https://yupa.io/";
      }, 200);
    },
  },
});
