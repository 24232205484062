import { I18n, createI18n } from "vue-i18n";

import enUs from "./locales/en.json";
import esEs from "./locales/es.json";
import { useAppStore } from "@/piniaStore/AppStore";

type MessageSchema = typeof enUs;

const getI18n = (): I18n<
  MessageSchema,
  Record<string, unknown>,
  Record<string, unknown>,
  "es" | "en",
  true
> => {
  const appStore = useAppStore();

  const lang = localStorage.getItem("language");

  if (!lang) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, browserLang] = navigator.languages;
    appStore.setLanguage(["es", "en"].includes(browserLang) ? (browserLang as "es" | "en") : "en");
    browserLang == "es"
      ? localStorage.setItem("language", "es")
      : localStorage.setItem("language", "en");
  }

  const language = appStore.language;

  const i18n = createI18n<[MessageSchema], "en" | "es">({
    legacy: false,
    locale: language,
    messages: {
      en: enUs,
      es: esEs,
    },
    datetimeFormats: {
      en: {
        long: {
          year: "numeric",
          month: "long",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        },
        short: {
          year: "numeric",
          month: "short",
          day: "numeric",
        },
        time: {
          hour: "2-digit",
          minute: "2-digit",
        },
      },
      es: {
        long: {
          year: "numeric",
          month: "long",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        },
        short: {
          year: "numeric",
          month: "short",
          day: "numeric",
        },
        time: {
          hour: "2-digit",
          minute: "2-digit",
        },
      },
    },
  });

  return i18n as unknown as I18n<
    MessageSchema,
    Record<string, unknown>,
    Record<string, unknown>,
    "es" | "en",
    true
  >;
};

export default getI18n;
