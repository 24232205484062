import axios, { AxiosResponse } from "axios";
import { getMeResponseType, postLoginResponseType, postRegisterResponseType } from "yupa-types";
import { baseNodesResponseType } from "./NodeService";
import { BaseHttpService } from "./BaseHttpService";

export class AuthService extends BaseHttpService {
  async login({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<postLoginResponseType> {
    const {
      data: { data: responseData },
    }: AxiosResponse<baseNodesResponseType<postLoginResponseType>> =
      await this.nonAuthenticatedInstance.post("/api/v1/auth/login", {
        email,
        password,
      });
    return responseData;
  }

  async getMe(): Promise<getMeResponseType> {
    try {
      const response : AxiosResponse<baseNodesResponseType<getMeResponseType>> =
        await this.authenticatedInstance.get(`/api/v1/auth/me`);

      
      return response.data.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 403) {
          return err.response.data.data;
        }
        throw err;
      }
      throw err;
    }
  }

  async register({
    email,
    password,
    name,
    notBotToken,
    referralCode,
  }: {
    email: string;
    password: string;
    name: string;
    notBotToken: string;
    referralCode?: string;
  }): Promise<postRegisterResponseType> {
    const {
      data: { data: registerResponse },
    }: AxiosResponse<baseNodesResponseType<postRegisterResponseType>> =
      await this.nonAuthenticatedInstance.post("/api/v1/auth/register", {
        email,
        password,
        name,
        notBotToken,
        referralCode,
      });

    return registerResponse;
  }

  async verifyEmail(token: string): Promise<void> {
    await this.authenticatedInstance.post("/api/v1/auth/verify-email", { verifyToken: token });
  }

  async requestResendVerifyEmail({ email }: { email: string }): Promise<void> {
    await this.authenticatedInstance.post("/api/v1/auth/resend-token", { email });
  }
  async requestPasswordReset(email: string): Promise<void> {
    await this.authenticatedInstance.post("/api/v1/auth/reset-password", { email });
  }

  async resetPassword({
    code,
    email,
    password,
  }: {
    code: string;
    email: string;
    password: string;
  }): Promise<void> {
    await this.nonAuthenticatedInstance.post("/api/v1/auth/reset-password-validate", {
      email,
      new_password: password,
      code,
    });
  }
}
