import AxiosNodes from "@/libs/axios/NodeAxios";
import NodeAxiosNoDecryptKey from "@/libs/axios/NodeAxiosNoDecryptKey";
import { NodeAxiosNonAuth } from "@/libs/axios/NodeAxiosNonAuth";
import { AxiosInstance } from "axios";

export abstract class BaseHttpService {
    authenticatedInstance: AxiosInstance
    nonAuthenticatedInstance: AxiosInstance
    authenticatedInstanceWithNodeKeys: AxiosInstance

    constructor(){
        this.authenticatedInstance = NodeAxiosNoDecryptKey
        this.nonAuthenticatedInstance = NodeAxiosNonAuth
        this.authenticatedInstanceWithNodeKeys = AxiosNodes
    }
}