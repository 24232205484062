import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "base-toast-notification" }
const _hoisted_2 = { class: "base-toast-notification-left" }
const _hoisted_3 = { class: "base-toast-notification-icon" }
const _hoisted_4 = { class: "base-toast-notification-right" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "base-toast-notification-icon-container",
        style: _normalizeStyle({
          background: _ctx.titleColor,
        })
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Icon, { icon: _ctx.iconName }, null, 8, ["icon"])
        ])
      ], 4)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("strong", {
          style: _normalizeStyle({ color: _ctx.titleColor })
        }, _toDisplayString(_ctx.title), 5)
      ]),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.info), 1)
    ])
  ]))
}