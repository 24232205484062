import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["width", "height", "src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.width)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        width: _ctx.width,
        height: _ctx.height,
        src: require(`@/assets/icons/${_ctx.icon}.svg`),
        class: "icon"
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("img", {
        key: 1,
        src: require(`@/assets/icons/${_ctx.icon}.svg`),
        class: "icon"
      }, null, 8, _hoisted_2))
}