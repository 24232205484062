import { toast as newToast } from "vue3-toastify";
import BaseToastNotification from "@/components/global/toasts/BaseToastNotification.vue";

export type useNewNotificationIcons =
  | "money_in"
  | "money_out"
  | "success"
  | "error"
  | "copy-green"
  | "wallet-green"
  | "wallet-red";

export const useNewToast = ({
  icon,
  title,
  type,
  info,
  id,
}: {
  title: string;
  info?: string;
  icon: useNewNotificationIcons;
  type: "success" | "error" | "info" | "warning";
  id?: string;
}): void => {
  newToast(<BaseToastNotification title={title} info={info} iconType={icon} type={type} />, {
    toastId: id,
  });
};
