
import { PropType, defineComponent } from 'vue';
import { IconStringType } from '../../interfaces/iconInterface';

export default defineComponent({
  name: "base-icon",
  props: {
    icon: String as PropType<IconStringType>,
    width: Number,
    height: Number
  },
});
