import { useAppStore } from "@/piniaStore/AppStore";
import { useAuthStore } from "@/piniaStore/AuthStore";
import axios from "axios";
import { axiosErrorInterceptor } from "./axiosUtils";
export const NodeAxiosNoDecryptKey = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_NODES_API,
  // timeout: 1000,
  headers: { accept: "application/json" },
});

NodeAxiosNoDecryptKey.interceptors.request.use((config) => {
  const authStore = useAuthStore();
  const appStore = useAppStore();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (config as Record<string, any>).headers.Authorization = `Bearer ${authStore.token}`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (config as Record<string, any>).headers["accept-language"] = appStore.language;

  return config;
});

NodeAxiosNoDecryptKey.interceptors.response.use((res) => res, axiosErrorInterceptor);

export default NodeAxiosNoDecryptKey;
