
import { PropType, defineComponent, computed } from "vue";

import Icon from "../../BASE/Icon.vue";
import { IconStringType } from "@/interfaces/iconInterface";
import { useNewNotificationIcons } from "@/hooks/useNewToast";

export default defineComponent({
  name: "base-toast-notification",
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      required: false,
    },
    iconType: {
      type: String as PropType<useNewNotificationIcons>,
      defaul: () => "success",
    },
    type: {
      type: String as PropType<"success" | "error" | "info" | "warning">,
    },
  },
  setup(props) {
    const iconName = computed(() => {
      let icon: IconStringType = "notifications";

      switch (props.iconType) {
        case "error":
          icon = "danger-thic-red";
          break;
        case "success":
          icon = "done-green";
          break;
        case "money_in":
          icon = "paid-green";
          break;
        case "money_out":
          icon = "paid-red";
        case "copy-green":
          icon = "copy-green";
          break;
        case "wallet-green":
          icon = "better_wallet_green";
          break;
        case "wallet-red":
          icon = "better_wallet_red";
          break;
      }

      return icon;
    });

    const titleColor = computed(() => {
      let color = "black";
      switch (props.type) {
        case "success":
          color = "#3AB35C";
          break;
        case "error":
          color = "#EA4E2E";
          break;
        case "info":
          color = "blue";
          break;
      }
      return color;
    });

    return {
      iconName,
      titleColor,
    };
  },
});
