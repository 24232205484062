import { onClickOutside, onKeyStroke } from "@vueuse/core";
import { Ref, ref } from "vue";

export const useOnClickOutside = (cb: () => void): {target: Ref} => {
  const target = ref(null);

  onClickOutside(target, cb);
  onKeyStroke("Escape", cb);

  return {
    target,
  };
};
