import axios from "axios";
import { useAppStore } from "@/piniaStore/AppStore";


export const NodeAxiosNonAuth = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_NODES_API,
  // timeout: 1000,
  headers: { accept: "application/json" }
});

NodeAxiosNonAuth.interceptors.request.use((config) => {
  const appStore = useAppStore();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (config as Record<string, any>).headers["accept-language"] = appStore.language;

  return config;
});
