import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "Home" */ "@/layouts/default.vue"),
    meta: { requiresAuth: true, role_id: 1 },
    children: [
      {
        path: "/",
        name: "Index",
        component: () => import(/* webpackChunkName: "Dashboard" */ "@/views/Dashboard.vue"),
        meta: { requiresAuth: true, role_id: 1 },
      },
      {
        path: "/wallet",
        name: "Wallet",
        component: () => import(/* webpackChunkName: "Wallet" */ "@/views/Wallet.vue"),
        meta: { requiresAuth: true, role_id: 1 },
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import(/* webpackChunkName: "Dashboard" */ "@/views/Dashboard.vue"),
        meta: { requiresAuth: true, role_id: 1 },
      },
      {
        path: "/posList/",
        name: "PosList",
        component: () => import(/* webpackChunkName: "PosList" */ "@/views/PosList.vue"),
        meta: { requiresAuth: true, role_id: 1 },
      },
      {
        path: "/nodes",
        name: "Nodes",
        component: () => import(/* webpackChunkName: "Node" */ "@/views/Node/NodePage.vue"),
        meta: { requiresAuth: true, role_id: 1 },
        beforeEnter: async (to, from, next) => {
          const nodeService = new NodeService();
          try {
            await nodeService.getHasNode();

            // check if we have node decryption key
            const secretKey = sessionStorage.getItem("nodeSecret");
            if (!secretKey) {
              next({ name: "NodeSecretKey" });
              return;
            }
            next();
          } catch (err) {
            if (axios.isAxiosError(err)) {
              const axiosError = err as AxiosError;
              if (axiosError.response?.status == 404) {
                next({ name: "NoNode" });
                return;
              }
              next("login");
              return;
            }
            next("/login");
            return;
          }
        },
      },
      {
        path: "/nodes/no-node",
        name: "NoNode",
        component: () => import(/* webpackChunkName: "NoNode" */ "@/views/Node/NoNode.vue"),
        meta: { requiredAuth: true, role_id: 1 },
        beforeEnter: async (to, from, next) => {
          const nodeService = new NodeService();
          try {
            await nodeService.getHasNode();
            next({ name: "Nodes" });
          } catch (err) {
            if (axios.isAxiosError(err)) {
              const axiosError = err as AxiosError;
              if (axiosError.response?.status == 404) {
                next();
                return;
              }
              next("login");
            }
            next("/login");
          }
        },
      },
      {
        path: "/nodes/secret-key",
        name: "NodeSecretKey",
        component: () => import(/* webpackChunkName: "NoNode" */ "@/views/Node/NodeSecretKey.vue"),
        meta: { requiredAuth: true, role_id: 1 },
      },
      {
        path: "/nodes/add-node",
        name: "LinkNode",
        component: () => import(/* webpackChunkName: "NoNode" */ "@/views/Node/LinkNode.vue"),
        meta: { requiredAuth: true, role_id: 1 },
        beforeEnter: async (to, from, next) => {
          const nodeService = new NodeService();
          try {
            await nodeService.getHasNode();
            next({ name: "Nodes" });
          } catch (err) {
            if (axios.isAxiosError(err)) {
              const axiosError = err as AxiosError;
              if (axiosError.response?.status == 404) {
                next();
                return;
              }
              next("login");
            }
            next("/login");
          }
        },
      },
      {
        path: "/setting",
        name: "Setting",
        component: () => import(/* webpackChunkName: "Setting" */ "@/views/Setings.vue"),
        meta: { requiresAuth: true, role_id: 1 },
      },
      {
        path: "/referral",
        name: "Referral",
        component: () => import(/* webpackChunkName: "Referral" */ "@/views/Referral.vue"),
        meta: { requiresAuth: true, role_id: 1 },
      },
      {
        path: "/tfa",
        name: "TwoFactorAuth",
        component: () => import(/* webpackChunkName: "tfa" */ "@/views/TwoFactorAuth.vue"),
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: "/pos/:id",
    name: "Pos",
    component: () => import(/* webpackChunkName: "Pos" */ "@/views/Pos.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "Login"  */ "@/views/login/Login.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import(/* webpackChunkName: "Register"  */ "@/views/login/Register.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/verify-email",
    name: "VerifyEmail",
    component: () => import(/* webpackChunkName: "VerifyEmail"  */ "@/views/login/VerifyEmail.vue"),
    meta: { requiresAuth: true },
  },{
    path: "/banned",
    name: "Banned",
    component: () => import(/* webpackChunkName: "Banned"  */ "@/views/login/BannedScreen.vue"),
    meta: { requiresAuth: true, role_id: 1 },
  },
  {
    path: "/recover",
    name: "Recover",
    component: () => import(/* webpackChunkName: "Recover"  */ "@/views/login/Recover.vue"),
    meta: { requiresAuth: false },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

import { useAuthStore } from "@/piniaStore/AuthStore";
import { useAppStore } from "@/piniaStore/AppStore";
import { NodeService } from "@/services/httpServies/NodeService";
import axios, { AxiosError } from "axios";

router.afterEach(async () => {
  setTimeout(() => {
    const element = document.getElementById("app-splash-screen");
    if (element) {
      element.style.display = "none";
    }
  }, 500);
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  if (to.name == "VerifyEmail") {
    next();
    return;
  }

  const { requiresAuth, role_id } = to.meta;
  if (to == from) {
    next();
    return;
  }
  if (!requiresAuth) {
    next();
    return;
  }

  const token = authStore.token;

  if (!token || token == "") {
    next("/login");
    return;
  }

  try {
    //await authStore.getMe();
    const userRoleId = authStore.user?.role_id;
    if (userRoleId == role_id) {
      next();
      return;
    } else {
      console.log("role id not match");
      next("/login");
    }
  } catch (err) {
    next();
  }
});

router.afterEach(async () => {
  const appStore = useAppStore();
  setTimeout(appStore.closeSideNave, 500);
});
export default router;
