import { AxiosResponse } from "axios";

import {
  GetChainBalanceResult,
  GetChannelBalanceResult,
  GetInvoicesResult,
  GetNodeResult,
  GetWalletInfoResult,
} from "lightning";

import {
  CreateNodeOnChainAddresseType,
  GetOnChainTransactionsResponseType,
  NodeStatusEnum,
  OnChainTransactionsype,
  getNodePaymentsHistoryResponseType,
  getNodeStatusResponseType,
  sendToOnChainResponseType,
  walletType,
} from "yupa-types";
export interface baseNodesResponseType<T> {
  data: T;
  message: string;
  code: number;
}

import { CreateInvoiceResult, PayViaPaymentRequestResult } from "lightning";
import { BaseHttpService } from "./BaseHttpService";

export interface nodeTransactionsStateType {
  openCreateInvoice?: boolean;
  openShowPayInvoice?: boolean;
  invoiceToPayBolt?: string;
  waitingToPayTimout?: boolean;
  isWaitingForPayment?: boolean;
  invoiceToReceive?: CreateInvoiceResult;
  openShowToReceiveInvoice: boolean;
  openShowTransferYupa: boolean;
  transferYupaVariant: "send" | "receive";
}

export type createNodeInvoiceResponseType = baseNodesResponseType<CreateInvoiceResult>;
export type transferToYupaResponseType = baseNodesResponseType<PayViaPaymentRequestResult>;

// Response Types
export type nodesNodeInfoResponse = baseNodesResponseType<GetNodeResult>;
export type nodesWalletInfoResponse = baseNodesResponseType<GetWalletInfoResult>;

export type nodesBalanceData = {
  chaineBalance: number;
  chanellBalance: GetChannelBalanceResult;
  tottalBalance: number;
};
export type nodesBalanceResult = baseNodesResponseType<nodesBalanceData>;
export type nodesOnChaineBalanceResult = baseNodesResponseType<GetChainBalanceResult>;
export type nodesChannelBalanceResult = baseNodesResponseType<GetChainBalanceResult>;

export type nodesPaymentsType = GetInvoicesResult["invoices"][0];
export type nodePaymentsResults = baseNodesResponseType<{
  invoices: nodesPaymentsType[];
  meta: { next: string };
}>;
// state Type
export interface NodeStateType {
  hasNode: boolean;
  wallet?: GetWalletInfoResult;
  node?: GetNodeResult;
  balances?: {
    chaineBalance: GetChainBalanceResult;
    chanellBalance: GetChannelBalanceResult;
    tottalBalance: number;
  };
  nodeTransactions?: nodeTransactionsStateType;
}

// class

export class NodeService extends BaseHttpService {
  async getHasNode(): Promise<void> {
    await this.authenticatedInstance.get("api/v1/nodes/manage-node/has-node");
  }

  async validateKey(secretKey: string): Promise<void> {
    await this.authenticatedInstance.get("api/v1/nodes/manage-node/validate-key", {
      headers: {
        "X-NODE-ACTIVATION-KEY": secretKey,
      },
    });
  }

  async linkNode({
    activationId,
    activationSecret,
    newActivationSecret,
  }: {
    activationId: string;
    activationSecret: string;
    newActivationSecret: string;
  }): Promise<void> {
    await this.authenticatedInstance.post("api/v1/nodes/manage-node/add-node", {
      activationId: activationId,
      activationKey: activationSecret,
      newActivationKey: newActivationSecret,
    });
  }

  async getNodeInfo(): Promise<GetNodeResult> {
    const { data }: AxiosResponse<nodesNodeInfoResponse> =
      await this.authenticatedInstanceWithNodeKeys.get("/api/v1/nodes/manage-node/node-info");

    const nodeInfo = data.data;
    return nodeInfo;
  }

  async getBalance(): Promise<nodesBalanceData> {
    const { data }: AxiosResponse<nodesBalanceResult> =
      await this.authenticatedInstanceWithNodeKeys.get("/api/v1/nodes/balance/");

    const balances = data.data;

    return balances;
  }

  async createNodeInvoice({
    memo,
    amount,
  }: {
    memo: string;
    amount: number;
  }): Promise<CreateInvoiceResult> {
    const { data }: AxiosResponse<createNodeInvoiceResponseType> =
      await this.authenticatedInstanceWithNodeKeys.post("/api/v1/nodes/wallet/create-invoice", {
        memo,
        amount,
      });

    return data.data;
  }

  async sendToYupaWallet({
    memo,
    amount,
    wallet,
  }: {
    memo: string;
    amount: number;
    wallet: walletType;
  }): Promise<void> {
    await this.authenticatedInstanceWithNodeKeys.post(
      "api/v1/nodes/wallet/transfer-to-yupa",
      {
        memo,
        amount,
      },
      {
        headers: {
          "X-Api-Key": wallet.adminkey,
        },
      }
    );
  }

  async receiveFromYupaWallet({
    memo,
    amount,
    wallet,
  }: {
    memo: string;
    amount: number;
    wallet: walletType;
  }): Promise<void> {
    await this.authenticatedInstanceWithNodeKeys.post(
      "api/v1/nodes/wallet/transfer-from-yupa",
      {
        memo,
        amount,
      },
      {
        headers: {
          "X-Api-Key": wallet.adminkey,
        },
      }
    );
  }

  async getNodePayments({
    paymentsToken,
    invoicesToken,
  }: {
    paymentsToken: string | null;
    invoicesToken: string | null;
  }): Promise<getNodePaymentsHistoryResponseType> {
    const {
      data: { data },
    }: AxiosResponse<baseNodesResponseType<getNodePaymentsHistoryResponseType>> =
      await this.authenticatedInstanceWithNodeKeys.get("/api/v1/nodes/payments", {
        params: { paymentsToken, invoicesToken },
      });

    return data;
  }

  async getNodeStatus(): Promise<NodeStatusEnum> {
    const { data }: AxiosResponse<baseNodesResponseType<getNodeStatusResponseType>> =
      await this.authenticatedInstanceWithNodeKeys.get("/api/v1/nodes/manage-node/node-status");

    return data.data.status;
  }

  async getOnChainAddress(type: "p2tr" | "np2wpkh"): Promise<string> {
    const { data }: AxiosResponse<baseNodesResponseType<CreateNodeOnChainAddresseType>> =
      await this.authenticatedInstanceWithNodeKeys.post("/api/v1/nodes/on-chain/create-addresse", {
        type,
      });

    return data.data.addresse;
  }

  async getOnChainTransactions(): Promise<OnChainTransactionsype[]> {
    const { data }: AxiosResponse<baseNodesResponseType<GetOnChainTransactionsResponseType>> =
      await this.authenticatedInstanceWithNodeKeys.get("/api/v1/nodes/on-chain/payments");

    return data.data.transactions;
  }

  async sendOnChain({
    address,
    amount,
    description,
  }: {
    amount: number;
    description: string;
    address: string;
  }): Promise<sendToOnChainResponseType> {
    const { data }: AxiosResponse<baseNodesResponseType<sendToOnChainResponseType>> =
      await this.authenticatedInstanceWithNodeKeys.post("/api/v1/nodes/on-chain/send-to-address", {
        amount,
        description,
        address,
      });

    return data.data;
  }
}
