
import { defineComponent } from "vue";

import AddToHomeScreen from "./components/global/AddToHomeScreen.vue";

export default defineComponent({
  name: "app-root",
  components: {
    AddToHomeScreen
  }
});
