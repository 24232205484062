import { defineStore } from "pinia";
import { PiniaStores } from ".";
import * as PusherPushNotifications from "@pusher/push-notifications-web";

export type CurrenciesType = "sat" | "usd" | "ves" | "inr" | "php" | "pkr";
export const currrenciesArray = ["sat", "usd", "ves", "inr", "php", "pkr"];
export const currrenciesObject = [
  {
    cur: "sat",
    flag: "",
  },
  {
    cur: "usd",
    flag: "usa",
  },

  {
    cur: "ves",
    flag: "ven",
  },
  {
    cur: "inr",
    flag: "ind",
  },
  {
    cur: "php",
    flag: "phl",
  },
  {
    cur: "pkr",
    flag: "pak",
  },
];

type State = {
  isSideNavOpen: boolean;
  currency: CurrenciesType;
  language: "es" | "en";
  beamClient?: PusherPushNotifications.Client | null;
  isYupaFeeActive: boolean;
};

export const useAppStore = defineStore(PiniaStores.APP_STORE, {
  persist: true,
  state: (): State => {
    const localStorageCurrency = localStorage.getItem("currency") ?? "";
    const currency = currrenciesArray.includes(localStorageCurrency) ? localStorageCurrency : "sat";
    return {
      currency: currency as CurrenciesType,
      isSideNavOpen: false,
      beamClient: null,
      language: "en",
      isYupaFeeActive: true,
    };
  },
  actions: {
    getCurrencySymbol() {
      switch (this.currency) {
        case "sat":
          return "sat";
        case "usd":
          return "$";
        case "inr":
          return "₹";
        case "ves":
          return "Bs";
        case "php":
          return "₱";
        case "pkr":
          return "₨";
      }
    },
    setLanguage(language: "en" | "es") {
      this.language = language;
    },
    openSideNav(): void {
      this.isSideNavOpen = true;
    },
    closeSideNave() {
      this.isSideNavOpen = false;
    },
    triggerSideNav() {
      this.isSideNavOpen = !this.isSideNavOpen;
    },
    setCurrency(currency: CurrenciesType) {
      this.currency = currency;
    },
    setBeamClient(client: PusherPushNotifications.Client): void {
      this.beamClient = client;
    },
    async stopBeamClient() {
      await this.beamClient?.stop();
      this.beamClient = null;
    },
  },
});
