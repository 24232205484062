import { useAuthStore } from "@/piniaStore/AuthStore";
import axios from "axios";
import getI18n from "../i18n";
import router from "@/router";
import { AxiosError } from "axios";
import { AxiosResponse } from "axios";
import { baseNodesResponseType } from "@/types/nodesApiResponseTypes";
import { postRefreshTokenResponseType } from "yupa-types";
import AxiosNodes from "./NodeAxios";
import { useNewToast } from "@/hooks/useNewToast";

const SESSION_EXPIRED_TOAST_ID = "SESSION_EXPIRED_TOAST_ID";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const axiosErrorInterceptor = async (err: any) => {
  const originalConfig = err.config;
  const authStore = useAuthStore();
  const {
    global: { t },
  } = getI18n();

  if (axios.isAxiosError(err)) {
    const axiosError = err as AxiosError<Record<string, unknown>>;
    if (axiosError.response) {
      if (axiosError.response.status === 401) {
        // Access Token was expired
        if (axiosError.response.data.message == "token expired" && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            const { data }: AxiosResponse<baseNodesResponseType<postRefreshTokenResponseType>> =
              await AxiosNodes.post("api/v1/auth/refresh-token", {
                refreshToken: authStore.refreshToken,
              });

            const { refreshToken, token } = data.data;
            authStore.updateTokens({ refreshToken, token });

            return AxiosNodes(originalConfig);
          } catch (_error) {
            useNewToast({
              id: SESSION_EXPIRED_TOAST_ID,
              icon: "error",
              type: "error",
              title: t("notifications.sessionExpired"),
              info: t("notifications.loginAgain"),
            });
            return router.push("/login");
          }
        } else if (
          axiosError.response.data.message &&
          (axiosError.response.data.message as string).includes("node-key")
        ) {
          sessionStorage.removeItem("nodeSecret");
          router.push({ name: "NodeSecretKey" });
        } else {
          useNewToast({
            id: SESSION_EXPIRED_TOAST_ID,
            icon: "error",
            type: "error",
            title: t("notifications.sessionExpired"),
            info: t("notifications.loginAgain"),
          });
          return router.push("/login");
        }
      } else if (axiosError.response?.status == 403) {
        return router.push({ name: "VerifyEmail" });
      }
    }

    return Promise.reject(err);
  }

  return Promise.reject(err);
};
